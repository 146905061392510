<script>

export default {
  data () {
    return {
      name: 'hello'
    }
  },
  mounted () {
  }
}
</script>

<template>
  <div class="personalCenter">
    <img src="@/assets/DefaultPersonalBackground.png" alt="背景图片无法加载" class="personalCenterImg" />
    <div class="headshot">
      <img src="@/assets/adm.png" alt="无法加载头像">
      <el-button type="primary" plain>修改头像</el-button>
    </div>
    <div class="ChangeInfo">
      <el-divider content-position="center">基本资料</el-divider>
      <span class="change-form">id:1111</span>
      <div class="change-form changePhone">
        <span>昵称：AScion</span>
        <el-button type="primary" plain>修改昵称</el-button>
      </div>
      <el-divider content-position="center">账号安全</el-divider>
      <div class="change-form changePhone">
        <span>用户名：AScion</span>
        <el-button type="primary" plain>修改用户名</el-button>
      </div>
      <div class="change-form changePhone">
        <span>手机号: 151*****514</span>
        <el-button type="primary" plain>修改手机号</el-button>
      </div>
      <div class="change-form">
        <span>密码强度: 强</span>
        <el-button type="primary" plain>修改密码</el-button>
      </div>
      <el-divider content-position="center">账号操作</el-divider>
      <div class="change-form">
        <el-button type="primary" plain>注销账号</el-button>
        <el-button type="primary" plain>我要反馈</el-button>
        <el-button type="primary" plain>联系开发者</el-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">

.personalCenter{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
}
.personalCenterImg{
  width: 100%;
  height:30%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  opacity: 0.5;
  z-index: 10;
}
.headshot{
  width: 100%;
  height: 30%;
  position: relative;
  z-index: 100;
}
.headshot img{
  height: 100%;
  border-radius: 25px;
}
.ChangeInfo{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ChangeInfo > :last-child{
  margin-bottom: 2%;
}
.form-floating{
  width: 40%;
}
.change-form{
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 1%;
}
</style>
