<script >
import PersonalInformation from '@/components/PersonalInformation.vue'
import DistributionMap from '@/components/DistributionMap.vue'
import ScoreLineChart from '@/components/ScoreLineChart.vue'
export default {
  name: 'HomePage',
  components: {
    PersonalInformation,
    DistributionMap,
    ScoreLineChart
  }
}

</script>

<template>
  <div class="home-page">
    <div class="home-page-header">
      <PersonalInformation></PersonalInformation>
      <DistributionMap></DistributionMap>
    </div>
    <div class="home-page-footer">
      <ScoreLineChart/>
    </div>
  </div>
</template>

<style scoped lang="less">
.home-page{
  margin: 1% 1%;
  display: flex;
  flex-direction: column;
  height: 96%;
}
.home-page-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48%;
}
.home-page-footer{
  width: 100%;
  height: 48%;
}
</style>
