<template>
  <div class="UserManagement">
    <el-tabs :tab-position="tabPosition" style="height: 200px;">
      <el-tab-pane label="学生账号管理">学生账号管理<AccountTable/></el-tab-pane>
      <el-tab-pane label="教师账号管理">教师账号管理</el-tab-pane>
      <el-tab-pane label="管理员账号管理">管理员账号管理</el-tab-pane>
      <el-tab-pane label="新增账号"><AddAccount/></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AccountTable from '@/components/AccountTable.vue'
import AddAccount from '@/components/AddAccount.vue'
export default {
  data () {
    return {
      tabPosition: 'top'
    }
  },
  components: {
    AccountTable,
    AddAccount
  }
}
</script>

<style scoped lang="less">
  .UserManagement{
    margin: 1% 1%;
    width: 98%;
    height: 96%;
  }
</style>
