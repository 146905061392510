import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import TimeTable from '@/views/TimeTable.vue'
import ScoreDetails from '@/views/ScoreDetails.vue'
import UserManagement from '@/views/UserManagement.vue'
import PersonalCenter from '@/views/PersonalCenter.vue'
import HOME from '@/views/HOME.vue'
import LoginPage from '@/views/LoginPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/home',
    redirect: '/home/index',
    component: HOME,
    children: [{
      path: '/home/index',
      component: HomePage
    },
    {
      path: '/home/timetable',
      component: TimeTable
    },
    {
      path: '/home/score-details',
      component: ScoreDetails
    },
    {
      path: '/home/user-management',
      component: UserManagement
    },
    {
      path: '/home/personalCenter',
      component: PersonalCenter
    }]

  }
]

const router = new VueRouter({
  routes
})

export default router
