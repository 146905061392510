<script>
export default {
  data () {
    return {
      activeNames: ['1']
    }
  },
  methods: {
    handleChange (val) {
    }
  }
}
</script>

<template>
  <el-collapse v-model="activeNames" @change="handleChange" accordion>
    <el-collapse-item title="有效性 Consistency" name="1">
      <div>必须确保账号有效性，请在添加完成后，在7个工作日内保证账号登录系统并修改密码</div>
      <div>暂无更多</div>
    </el-collapse-item>
  </el-collapse>
</template>

<style scoped lang="less">
</style>
