<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    max-height="250">
    <el-table-column
      fixed
      prop="id"
      label="账号id"
      >
    </el-table-column>
    <el-table-column
      prop="name"
      label="账号昵称"
      >
    </el-table-column>
    <el-table-column
      prop="phone"
      label="手机号"
      >
    </el-table-column>
    <el-table-column
      prop="ActiveTime"
      label="近期活跃时间"
      >
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <el-button type="text" size="small">编辑</el-button>
        <el-button
          @click.native.prevent="deleteRow(scope.$index, tableData)"
          type="text"
          size="small">
          移除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  methods: {
    deleteRow (index, rows) {
      rows.splice(index, 1)
    }
  },
  data () {
    return {
      tableData: [{
        id: 1,
        name: 'Ascion',
        phone: '15111711514',
        ActiveTime: '2024年8月15日16:46:47'
      }]
    }
  }
}
</script>
<style lang="less" scoped>
  .account-table {
    margin: 1% 1%;
    width: 98%;
    height: 98%;
  }
</style>
