<script>
export default {
  name: 'TopNav',
  data () {
    return {
      timer: null,
      current: null
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.current = new Date().toLocaleString()
    }, 1000)
  },
  beforeDestroy () {
    // Clear the timer before destroying the instance.
    clearInterval(this.timer)
  }
}
</script>

<template>
  <div class="top-nav">
    <div class="top-nav-item top-nav-left-item">
      <img src="@/assets/system.png" height="70%" alt="图片未加载" id="top-nav-item-system-img">
      <span>学生成绩管理系统</span>
    </div>
    <div class="top-nav-item top-nav-right-item">
      <span id="top-nav-item-time">{{ current }}</span>
      <slot></slot>

    </div>
  </div>
</template>

<style scoped lang="less">
  .top-nav {
    background-color: rgba(42,130,228,0.25);
    position: sticky;
    top: 0;
    padding: 0 6%;
    height: 10%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top-nav-item{
    height: 100%;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
  }
  #top-nav-item-system-img, #top-nav-item-time {
    margin: 0 2%;
  }
  a{
    text-decoration: none;
    font-size: 1.1em;
  }
</style>
