<script>
export default {
  name: 'AddedAccountTypes',
  props: [
    'accType'
  ],
  data () {
    return {
      options: [
        { value: 'teacher', label: '教师' },
        { value: 'student', label: '学生' }
      ],
      value: ''
    }
  },
  mounted () {
    this.value = this.accType
  }
}
</script>

<template>
  <div class="added-account-types">
    <el-select v-model="value" placeholder="请选择" @change="() => this.$emit('updateAccType', value)">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<style scoped lang="less">
  .added-account-types{
    margin: 1% 0;
  }
</style>
