<script>
import TopNav from '@/components/TopNav.vue'
import SizeNav from '@/components/SizeNav.vue'
export default {
  components: {
    TopNav,
    SizeNav
  },
  data () {
    return {
      authenticated: false
    }
  }
}

</script>

<template>
  <div class="index">
    <TopNav>
      <template v-if="this.authenticated">
        <img src="@/assets/adm.png" height="70%" alt="图片未加载">
        <span>管理员</span>
      </template>
      <template  v-else>
        <RouterLink to="/login">点击登陆</RouterLink>
      </template>
    </TopNav>
    <div class="center-content">
      <SizeNav></SizeNav>
      <div class="content">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.index{
  width: 100%;
}
.center-content{
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.content{
  width: 82%;
  height: 100%;
  border-radius: 25px;
  background: #fff;
  overflow: auto;
}
</style>
