<script>
import { PREFIXURL } from '@/WebConfig/WebConfig'
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'LoginCard',
  data () {
    return {
      userid: '',
      password: '',
      twoFactorPasswords: '',
      phone: '',
      versify: '',

      useridTip: '请输入用户名',
      passwordTip: '请输入密码',
      twoFactorPasswordsTip: '再次输入密码',
      phoneTip: '输入您的手机号',
      versifyTip: '输入六位验证码',

      cooling: false,
      registrerCooling: false,
      countdown: 0
    }
  },
  methods: {
    ...mapActions(['setAuthenticationTokens']),
    success () {
      this.$notify({
        title: '成功',
        message: '你现在可以通过' + (this.auth === 'password' ? '密码' : '验证码') + '登陆了',
        type: 'success'
      })
    },
    register (event) {
      this.registrerCooling = true
      event.target.innerText = '注册请求中'
      const respond = axios({
        url: PREFIXURL + '/register',
        method: 'POST',
        data: {
          ID: this.userid,
          password: this.password,
          twoFactorPassword: this.twoFactorPasswords,
          phone: this.phone,
          code: this.versify
        }
      })
      respond.then(res => {
        console.log(res.data)
        this.setAuthenticationTokens(res.data)
        event.target.innerText = '注册成功'
      })
      respond.catch(e => {
        console.log('认证失败了')
        event.target.innerText = '重新注册'
        this.registrerCooling = false
      })
    },
    sendVersify (event) {
      // 将发送按钮禁用
      this.cooling = true
      // 获取按钮
      const text = event.target
      text.innerText = '请求获取验证码...'

      const request = axios({
        url: PREFIXURL + '/sendAVerificationCode',
        method: 'POST',
        data: {
          phone: this.phone
        }
      })

      request.then(respond => {
        console.log(respond.data)
        if (respond.status === 201) {
          // 禁用按钮60秒，并显示剩余秒数
          this.startCountdown(text, 60)
        }
      })

      request.catch(error => {
        console.log(error)
        this.cooling = false
        text.innerText = '发送验证码'
      })
    },
    startCountdown (conponent, seconds) {
      this.countdown = seconds
      const intervalId = setInterval(() => {
        this.countdown--
        if (this.countdown <= 0) {
          clearInterval(intervalId)
          this.cooling = false
          conponent.innerText = '重新发送验证码'
        } else {
          conponent.innerText = `重新发送(${this.countdown}s)`
        }
      }, 1000)
    }
  }
}
</script>

<template>
  <div class="login-card">
    <el-input v-model="userid" :placeholder=this.useridTip autofocus></el-input>
    <el-input  v-model="password" :placeholder=this.passwordTip type="password"></el-input>
    <el-input  v-model="twoFactorPasswords" :placeholder=this.twoFactorPasswordsTip type='password'></el-input>
    <el-input  v-model="phone" :placeholder=this.phoneTip type='text' ></el-input>
    <div  class='pwd'>
      <el-input v-model="versify"  placeholder="验证码" maxlength="6" show-word-limit></el-input>
      <el-button type="primary"  @click="sendVersify" :disabled="cooling">发送验证码</el-button>
    </div>
    <el-button type="primary" plain @click="register" :disabled="registrerCooling">注册</el-button>
  </div>
</template>

<style scoped lang="less">
.login-card > *{
  margin-top: 1.5%;
  width: 60%;
  display: inline-block;
}
.other-auth{
  margin-top: 0;
}
.other-auth > img{
  margin-left: 4%;
}
.other-auth > img:hover{
  cursor: pointer;
}
.pwd-contain > *{
  display: inline-block;

}
.pwd > :first-child{
  width: 70%;
}
.pwd > :last-child{
  width: 30%;
}
</style>
