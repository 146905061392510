<script>
import * as echarts from 'echarts'
export default {
  name: 'DistributionMap',
  data () {
    return {}
  },
  props: {},
  mounted () {
    this.$nextTick(() => {
      // const dom = document.getElementById('container')
      const dom = this.$refs.container
      const myChart = echarts.init(dom, null, {
        renderer: 'canvas',
        useDirtyRect: false
      })

      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '成绩阶段分布',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: 1048,
                name: '不及格'
              },
              {
                value: 735,
                name: '及格'
              },
              {
                value: 580,
                name: '良好'
              },
              {
                value: 484,
                name: '优秀'
              },
              {
                value: 300,
                name: '满分'
              }
            ]
          }
        ]
      }
      if (option && typeof option === 'object') {
        myChart.setOption(option)
      }
      window.addEventListener('resize', myChart.resize)
    })
  }
}

</script>

<template>
  <div class="distribution-map">
    <div id="container" ref="container"></div>
  </div>
</template>

<style scoped lang="less">
.distribution-map{
  width: 50%;
  height: 100%;
  background-color: beige;
}
#container{
  height: 100%;
}
</style>
