<script>

import axios from 'axios'
import { PREFIXURL } from '@/WebConfig/WebConfig'
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('timetable', ['curriculum'])
  },
  mounted () {
    // 获取数据
    this.getCurriculum()
  },
  methods: {
    ...mapActions('timetable', ['setCurriculum']),
    getCurriculum () {
      if (this.curriculum.length !== 0) { return }
      console.log('请求获取课程表发出')

      const request = axios({
        url: PREFIXURL + '/get-curriculum'
      })
      request.then(response => {
        console.log(response)
        this.setCurriculum(response.data)
      })
    }
  }
}
</script>

<template>
  <div class="table-container">
    <table class="table">
      <tr>
        <th>#</th>
        <th>星期一</th>
        <th>星期二</th>
        <th>星期三</th>
        <th>星期四</th>
        <th>星期五</th>
        <th>编辑</th>
      </tr>
      <tr>
        <td><strong>第一、二节</strong></td>
        <td>
          大学英语III<br>
          胡雪婷(讲师(高校))<br>
          9-16[周]<br>
          文科综合实训大楼506<br>
        </td>
        <td>
          Java程序课程设计<br>
          张纲强(中级)<br>
          5-15[周]<br>
          逸夫楼605<br>
        </td>
        <td>
          Java程序设计<br>
          李熹(讲师(高校))<br>
          1-13[周]<br>
          逸夫楼605<br>
        </td>
        <td>
          离散数学<br>
          黄留佳(讲师(高校))<br>
          1-16[周]<br>
          校友楼201<br>
        </td>
        <td>
          概率论与数理统计<br>
          莫愿斌(副教授)<br>
          1-14[周]<br>
          校友楼201<br>
        </td>
        <td id="table-option">
          <el-button title="编辑" type="primary" icon="el-icon-edit" circle></el-button>
        </td>
      </tr>
      <tr>
        <td><strong>第三、四节</strong></td>
      </tr>
      <tr>
        <td><strong>第五、六节</strong></td>
      </tr>
      <tr>
        <td><strong>第七、八节</strong></td>
      </tr>
      <tr>
        <td><strong>第九、十节</strong></td>
      </tr>
    </table>
    <div class="table-description"><span>备注:</span></div>
  </div>
</template>

<style scoped lang="less">
@import url("//unpkg.com/element-ui@2.15.14/lib/theme-chalk/index.css");
.table-container{
  width: 100%;
  height: 100%;

}
.table{
  margin: auto;
  width: 90%;
  height: 90%;
  border: 1px solid lightgray;
}
.table th, .table td {
  border: 1px solid lightgray;
  word-break: break-all;
  overflow: hidden;
}
#table-option{
  width: 8%;
}
tr td{
  height: 16%;
}
</style>
