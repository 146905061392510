<template>
  <div class="admin-authentication">
  <el-select v-model="value" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
    <el-input
      placeholder="请输入认证内容"
      v-model="input"
      clearable>
    </el-input>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '0',
        label: '密码认证'
      }, {
        value: '1',
        label: '手机号认证'
      }],
      value: '0',
      input: ''
    }
  }
}
</script>

<style scoped lang="less">
*{
  margin-top: 2%;
  margin-bottom: 1%;
}
</style>
