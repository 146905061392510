<template>
  <div class="el-table">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="班级成绩分析" name="first">班级成绩分析</el-tab-pane>
      <el-tab-pane label="学生个人成绩分析" name="second">
        <PersonalGrades/>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
          录入学生成绩
        </button>
      </el-tab-pane>
    </el-tabs>
    <PopUps/>
  </div>
</template>
<script>
import PersonalGrades from '@/components/PersonalGrades.vue'
import PopUps from '@/components/PopUps.vue'
export default {
  components: {
    PersonalGrades,
    PopUps
  },
  data () {
    return {
      activeName: 'second'
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style scoped lang="less">
.el-tabs{
  margin: 1% 1%;
  width: 98%;
  height: 98%;
}
</style>
