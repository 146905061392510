<script>
import serializeForm from 'form-serialize'
export default {
  name: 'PopUps',
  data () {
    return {
      listOfInformation: [
        { name: 'id', desc: '学生学号' },
        { name: 'name', desc: '学生姓名' },
        { name: 'english', desc: '大学英语III分数' },
        { name: 'PTANS', desc: '概率论与数理统计分数' },
        { name: 'discreteMathematics', desc: '离散数学分数' }
      ]
    }
  },
  methods: {
    addAGrade () {
      const form = this.$refs.addForm
      const data = serializeForm(form, { hash: true, empty: true })
      const id = data.id
      const name = data.name
      const english = data.english
      const PTANS = data.PTANS
      const discreteMathematics = data.discreteMathematics

      if (id === null || id === undefined || id === '' || name === null || name === undefined || name === '') {
        this.btnError()
        return false
      }
      if (english === null || english === undefined || english === '' || !isNaN(Number(english)) || Number(english) < 0 || Number(english) > 100) {
        this.btnError()
        return false
      }
      if (PTANS === null || PTANS === undefined || PTANS === '' || !isNaN(Number(PTANS)) || Number(PTANS) < 0 || Number(PTANS) > 100) {
        this.btnError()
        return false
      }
      if (discreteMathematics === null || discreteMathematics === undefined || discreteMathematics === '' || !isNaN(Number(discreteMathematics)) || Number(discreteMathematics) < 0 || Number(english) > 100) {
        this.btnError()
        return false
      }
      // 提交到服务器
      this.btnSuccess('添加成功', '您已成功添加学生成绩', 'success')
    },
    // 成功消息提示
    btnSuccess (title, msg, type) {
      this.$notify({
        title: title,
        message: msg,
        type: type
      })
    },
    // 失败消息提示
    btnError () {
      this.$notify.error({
        title: '错误',
        message: '请检查是否存在不符合的输入格式'
      })
    },
    // 取消消息提示
    btnInfo () {
      this.$notify.info({
        title: '取消',
        message: '已取消添加操作'
      })
    }
  }
}
</script>

<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">录入新的学生成绩</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="btnInfo"></button>
        </div>
        <div class="modal-body">
          <form ref="addForm">
            <div class="form-floating" v-for="(item, index) in listOfInformation" :key="index">
              <input type="text" class="form-control" id="floatingPassword" placeholder="Password" :name="item.name">
              <label for="floatingPassword">{{ item.desc }}</label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="btnInfo">取消</button>
          <button type="button" class="btn btn-primary" @click="addAGrade">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.form-floating{
  margin-bottom: 2%;
}
</style>
