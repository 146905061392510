<script >
export default {
  props: [
    'accName',
    'accPwd'
  ],
  data () {
    return {
      auth: false,
      userName: '',
      password: ''
    }
  },
  mounted () {
    this.userName = this.accName
    this.password = this.accPwd
  }
}
</script>

<template>
<form class="form-horizontal">
  <el-input v-model="userName" placeholder="登陆用户名" @change="(e) => this.$emit('updateAccName', userName)"></el-input>
  <el-input v-model="password" placeholder="首次登陆密码" @change="(e) => this.$emit('updateAccPwd', password)"></el-input>
</form>
</template>

<style scoped lang="less">
.form-horizontal {
  width: 45%;
  margin: 1% 0;
}
.form-horizontal > *{
  margin: 1% 0;
}
</style>
