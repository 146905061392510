<template>
  <div class="login-page">
    <LoginBack/>
    <el-tabs class="tabs" v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="登陆" name="first">
        <LoginCard/>
      </el-tab-pane>
      <el-tab-pane label="账号注册" name="second">
        <RegisteredCard/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LoginBack from '@/components/LoginBack.vue'
import LoginCard from '@/components/LoginCard.vue'
import RegisteredCard from '@/components/RegisteredCard.vue'
export default {
  name: 'LoginPage',
  components: {
    LoginBack,
    LoginCard,
    RegisteredCard
  },
  data () {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style scoped lang="less">
*{
  margin: 0;
  padding: 0;
}
.login-page {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs{
  width: 50%;
  height: 55%;
  background-color: white;
}
</style>
