import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { PREFIXURL } from '@/WebConfig/WebConfig'
import timetable from '@/store/timetable/timetable'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 头象
    headshotUri: '',
    // 用户id
    userId: +'',
    // 用户昵称
    userName: '',
    // 手机号
    userPhone: '',
    // 登陆令牌
    authenticationTokens: null
  },
  getters: {
    getAuthenticationTokens: state => state.authenticationTokens
  },
  mutations: {

  },
  actions: {
    // 根据令牌获取账号信息
    obtainCertificationInformation ({ state }) {
      state.authenticationTokens = localStorage.getItem('token')
      if (state.authenticationTokens === null || state.authenticationTokens === undefined) {
        return
      }
      const webRequest = axios({
        url: PREFIXURL + '/get-account-information',
        params: {
          token: state.authenticationTokens
        }
      })
      // 请求成功
      webRequest.then(response => {
        console.log(response)
        if (response.status === 201) {
          console.log(state.userId)
          state.headshotUri = response.data.headshotUri
          state.userName = response.data.userName
          state.userPhone = response.data.userPhone
          state.userId = response.data.userId
          // 有新的有效时长的登录令牌
          state.authenticationTokens = response.data.authenticationTokens
        } else if (response.data.status === 304) {
          console.log('令牌无效')
        }
        console.log(response.data.message)
      })
      // 请求失败
      webRequest.catch(error => {
        console.error(error.response.data.message)
      })
    },
    setAuthenticationTokens ({ state }, token) {
      state.authenticationTokens = token
    }
  },
  modules: {
    timetable
  }
})
