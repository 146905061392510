<template>
  <div id="app">
    <RouterView class="view-container"/>
    <FootNav id="foot-nav"></FootNav>
  </div>
</template>

<script>

import FootNav from '@/components/FootNav.vue'
export default {
  name: 'App',
  components: {
    FootNav
  },
  mounted () {
    this.$store.dispatch('obtainCertificationInformation')
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  background-color: rgba(204,204,204,0.5);
}
.view-container{
  height: 95%;
}
#foot-nav{
  margin:auto;
}
</style>
