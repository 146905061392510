<script>
export default {
  name: 'SizeNav',
  data () {
    return {
      expandNavigation: true
    }
  },
  methods: {
  }
}
</script>

<template>
  <div class="size-nav">
    <div class="size-nav-items">
      <RouterLink to="/home/index" class="size-nav-item" id="home">
        <span>首页</span>
      </RouterLink>

      <RouterLink to="/home/timetable" class="size-nav-item" id="timetable">
        <span>课程表</span>
      </RouterLink>

      <RouterLink to="/home/score-details" class="size-nav-item" id="score-details">
        <span>成绩详情</span>
      </RouterLink>

      <RouterLink to="/home/user-management" class="size-nav-item" id="user-management">
        <span>用户管理</span>
      </RouterLink>

      <RouterLink to="/home/personalCenter" class="size-nav-item" id="personal-center">
        <span>个人中心</span>
      </RouterLink>
    </div>
  </div>
</template>

<style scoped lang="less">
  .size-nav {
    background-color: white;
    width: 15%;
    height: 100%;
  }
  .size-nav-items{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .size-nav-item{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px black solid;
    width: 100%;
    height: 8%;
    text-decoration: none;
    color: black;
  }
  .size-nav-item:hover{
    background-color: #CCCCCC;
    color: black;
  }
  .router-link-active{
    background-color: #2A82E4;
    color: white;
  }
  #score-details{
    width: 100%;
    height: 8%;
  }
</style>
