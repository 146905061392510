<script>
import axios from 'axios'
import { PREFIXURL } from '@/WebConfig/WebConfig'
import { mapState, mapGetters, mapActions } from 'vuex'
import router from '@/router/index'
export default {
  name: 'LoginCard',
  computed: {
    ...mapState(['authenticationTokens']),
    ...mapGetters(['getAuthenticationTokens'])
  },
  data () {
    return {
      idTip: '请输入用户名',
      dynamicCodesTip: '请输入密码',

      ID: '',
      dynamicCodes: '',
      // 认证类型 password-密码认证 versify-手机验证码
      inputType: 'password',
      auth: 'password',
      cooling: false
    }
  },
  methods: {
    ...mapActions(['setAuthenticationTokens']),
    usePassword () {
      this.ID = ''
      this.dynamicCodes = ''
      this.auth = 'password'
      this.idTip = '请输入用户名'
      this.dynamicCodesTip = '请输入密码'
      this.inputType = 'password'
      this.success()
    },
    useVersifyCodes () {
      this.ID = ''
      this.dynamicCodes = ''
      this.auth = 'versify'
      this.idTip = '请输入手机号'
      this.dynamicCodesTip = '请输入验证码'
      this.inputType = 'text'
      this.success()
    },
    loginSuccess () {
      this.$notify({
        title: '登陆成功',
        message: '即将返回主页',
        type: 'success'
      })
    },
    success () {
      this.$notify({
        title: '成功',
        message: '你现在可以通过' + (this.auth === 'password' ? '密码' : '验证码') + '登陆了',
        type: 'success'
      })
    },
    error () {
      this.$notify.error({
        title: '输入不合法',
        message: '你必须输入有效内容才能进行登录验证'
      })
    },
    sendVersify (event) {
      // 将发送按钮禁用
      this.cooling = true
      const text = event.target
      text.innerText = '验证码已发送'
    },
    login () {
      if (this.ID === '' || this.dynamicCodes === '') {
        this.error()
        return
      }

      const request = axios({
        url: PREFIXURL + '/login',
        method: 'post',
        data: {
          // 认证id
          ID: this.ID,
          // 认证动态验证码
          dynamicCodes: this.dynamicCodes,
          // 认证类型
          auth: this.auth
        }
      })
      request.then(reponsed => {
        this.loginSuccess()
        this.setAuthenticationTokens(reponsed.data.token)
        localStorage.setItem('token', reponsed.data.token)
        console.log(this.getAuthenticationTokens)

        setTimeout(() => {
          router.push('/')
          location.reload()
        }, 2000)
      })
      request.catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<template>
  <div class="login-card">
    <el-input v-model="ID" @keypress="login" :placeholder=this.idTip autofocus></el-input>
    <div  :class="{'pwd' : auth === 'versify'}">
      <el-input @keypress="login" v-model="dynamicCodes" :placeholder=this.dynamicCodesTip :type='this.inputType'></el-input>
      <el-button type="primary" v-if="auth === 'versify'" @click="sendVersify" :disabled="cooling">发送验证码</el-button>
    </div>
    <el-divider content-position="center">其他登陆方式</el-divider>
    <div class="other-auth">
      <img src="@/assets/user.png" alt="用户名登录" title="使用用户名和密码登录" @click="usePassword">
      <img src="@/assets/phone.png" alt="手机号登录" title="使用手机验证码登录" @click="useVersifyCodes">
      <img src="@/assets/qq.png" alt="QQ登陆" title="使用手机QQ登录">
    </div>
    <el-button type="primary" plain @keypress="login" @click="login">登陆</el-button>
  </div>
</template>

<style scoped lang="less">
.login-card > *{
  margin-top: 4%;
  width: 60%;
  display: inline-block;
}
.other-auth{
  margin-top: 0;
}
.other-auth > img{
  margin-left: 4%;
}
.other-auth > img:hover{
  cursor: pointer;
}
.pwd-contain > *{
  display: inline-block;

}
.pwd > :first-child{
  width: 70%;
}
.pwd > :last-child{
  width: 30%;
}
</style>
