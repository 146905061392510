<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userName', 'headshotUri', 'userPhone', 'userId'])
  }
}
</script>

<template>
  <div class="adm-brief-information">
    <div class="brief-option"> <RouterLink to="/home/personalCenter">修改资料</RouterLink> </div>
    <div class="brief-info">
      <img :src="headshotUri" alt="头像加载失败">
      <div class="text-information">
        <span>{{ userName }}</span>
        <div class="textual-information">
          <span>{{ userPhone }}</span>
          <span>{{ userId }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.brief-option{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

}
.brief-option>a{
  color: #2A82E4;
  text-decoration: none;
}
.adm-brief-information{
  width: 55%;
  height: 100%;
}
.brief-option{
  width: 100%;
  height: 15%;
  background-color: rgba(227,60,100,0.5);
}
.brief-info{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 85%;
}
.text-information{
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.textual-information{
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.textual-information > :first-child{
  margin-left: -15%;
}
</style>
