<template>
  <el-table
    :data="tableData"
    style="width: 100%">
    <el-table-column
      prop="id"
      label="学号"
      >
    </el-table-column>
    <el-table-column
      prop="name"
      label="姓名"
      >
    </el-table-column>
    <el-table-column
      prop="english"
      label="大学英语III">
    </el-table-column>
    <el-table-column
      prop="PTANS"
      label="概率论与数理统计">
    </el-table-column>
    <el-table-column
      prop="discreteMathematics"
      label="离散数学">
    </el-table-column>
    <el-table-column
      prop="score"
      label="总分">
    </el-table-column>
    <el-table-column
      prop="equipartition"
      label="平均分">
    </el-table-column>
    <el-table-column
      prop="classRank"
      label="班级排名">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template>
        <el-button type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data () {
    return {
      tableData: [
        { id: '2023******401', name: '蚊香', english: 98, PTANS: 98, discreteMathematics: 98, score: 294, equipartition: 98, classRank: 1 },
        { id: '2023******400', name: '蚊子', english: 97, PTANS: 98, discreteMathematics: 98, score: 293, equipartition: 97.66, classRank: 2 }
      ]
    }
  }
}
</script>
<style scoped>
</style>
