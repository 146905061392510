<script>
import AccountEditingAgreement from '@/components/AccountEditingAgreement.vue'
import AdminAuthentication from '@/components/AdminAuthentication.vue'
import AddedAccountInformation from '@/components/AddedAccountInformation.vue'
import AddedAccountTypes from '@/components/AddedAccountTypes.vue'
export default {
  components: {
    AccountEditingAgreement,
    AdminAuthentication,
    AddedAccountInformation,
    AddedAccountTypes
  },
  data () {
    return {
      steps: 1,
      accName: '',
      accPwd: '',
      accType: ''
    }
  },
  watch: {
    accName (newVal, oldVal) {
      console.log(newVal)
    },
    accPwd (newVal, oldVal) {
      console.log(newVal)
    },
    accType (newVal, oldVal) {
      console.log(newVal)
    }
  },
  computed: {
    getCurrentView () {
      switch (this.steps) {
        case 1:
          return AccountEditingAgreement
        case 2:
          return AdminAuthentication
        case 3:
          return AddedAccountInformation
        case 4:
          return AddedAccountTypes
        default:
          return null
      }
    }
  },
  methods: {
    async previousStep () {
      if (this.steps <= 1) {
        return
      }
      this.steps -= 1
      await this.$nextTick()
    },
    async nextStep () {
      if (this.steps >= 4) {
        return
      }
      this.steps += 1
      await this.$nextTick()
    },
    updateAccName (obj) {
      this.accName = obj
      console.log(this.accName)
    },
    updateAccPwd (obj) {
      this.accPwd = obj
      console.log(this.accPwd)
    },
    updateAccType (obj) {
      this.accType = obj
      console.log(this.accType)
    },
    emitForm () {
      console.log(this.accName + ' ' + this.accPwd + ' ' + this.accType)
    }
  }
}
</script>

<template>
  <div class="container">
    <el-steps :active="this.steps" align-center>
      <el-step title="阅读协议" description="阅读并同意账号编辑协议"></el-step>
      <el-step title="身份验证" description="管理员身份验证"></el-step>
      <el-step title="新增信息" description="输入新增账号信息"></el-step>
      <el-step title="账号类型" description="选择新增账号类型"></el-step>
      <el-step title="完成" description="等待服务器验证"></el-step>
    </el-steps>
    <component :is="getCurrentView"
                :accName="accName"
               :accPwd="accPwd"
               :accType="accType"
              @updateAccName="updateAccName"
              @updateAccPwd="updateAccPwd"
              @updateAccType="updateAccType"
    />
    <el-button-group>
      <el-button type="primary" icon="el-icon-arrow-left" @click="previousStep">上一个</el-button>
      <el-button type="primary" @click="nextStep" v-if="steps < 4">下一个<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button type="primary" v-else @click="emitForm">提交</el-button>
    </el-button-group>
  </div>
</template>

<style scoped lang="less">
.is-finish{
  color: #67c23a;
}
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container > :first-child{
  width: 100%;
}
.container > :nth-child(2){
  width: 50%;
}
</style>
