<script>
import * as echarts from 'echarts'
export default {
  name: 'ScoreLineChart',
  mounted () {
    this.$nextTick(() => {
      const chartDom = this.$refs.main
      const myChart = echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          hideDelay: 100
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 20, 34, 50, 33, 20]
          }
        ]
      }

      option && myChart.setOption(option)
    })
  }
}
</script>

<template>
<div id="container" ref="main"></div>
</template>

<style scoped lang="less">
#container {
  width: 100%;
  height: 100%;
}
</style>
