
const timetable = {
  namespaced: true,
  state: {
    curriculum: []
  },
  mutations: {

  },
  actions: {
    setCurriculum ({ state }, curriculum) {
      state.curriculum = curriculum
    }
  },
  getters: {

  }
}

export default timetable
